<template>
  <div>
    <div class="w-full">
      <div class="navbar flex flex-nowrap h-20 flex-col md:flex-row items-center hidden lg:flex s-container">
        <NuxtLink
          class="flex items-center ml-6"
          :to="'/'"
        >
          <NuxtImg
            :src="'/images/brand/logo.png'"
            width="350"
            alt="The Flowery Logo"
            :modifiers="{ q: 75 }"
          />
        </NuxtLink>
        <nav class="md:ml-auto flex flex-nowrap items-center text-base justify-center h-full">
          <div class="s-dropdown relative flex h-full items-center mr-3 xl:mr-7 px-6 cursor-pointer">
            <a class="s-submenu s-navbar-link s-menu-item-has-children">
              Menu
              <IconChevron class="s-navbar-default-chevron inline-block h-2 ml-2" />
            </a>
            <div class="s-dropdown-menu hidden h-auto group w-full top-20">
              <div class="relative flex s-dropdown-menu-top w-full">
                <div class="s-dropdown-menu-top-left">Specials</div>
                <div class="w-full overflow-hidden flex">
                  <Vue3Marquee :pause-on-hover="true">
                    <div class="flex w-full whitespace-nowrap">
                      <div
                        v-for="special of GET_HEADER_DATA.products"
                        :key="special.id"
                        class="ml-5"
                      >
                        <NuxtLink
                          v-if="special.product.price !== special.product.regular_price"
                          :to="'/product/' + special.product.post_name"
                          class="s-navbar-marque-text"
                          >{{ special.product.post_title }}
                          <span class="line-through">${{ special.product.regular_price }}</span>
                          <span class="text-white"> ${{ special.product.price }} </span>
                          <div class="ml-3 s-navbar-marque-btn">Shop</div>
                        </NuxtLink>
                        <NuxtLink
                          v-else
                          :to="'/product/' + special.product.post_name"
                          class="s-navbar-marque-text"
                          >{{ special.product.post_title }}
                          <span>${{ special.product.regular_price }}</span>
                          <div class="ml-3 s-navbar-marque-btn">Shop</div>
                        </NuxtLink>
                      </div>
                    </div>
                  </Vue3Marquee>
                </div>
              </div>
              <div class="flex p-10 mt-10 flex-grow justify-between bg-white">
                <div class="s-dropdown-submenu">
                  <a href="/shop">Categories</a>
                  <ul class="">
                    <li
                      v-for="category of GET_HEADER_DATA.weed_categories"
                      :key="category.weed_categoriy_title"
                      class="s-dropdown-menu-link"
                    >
                      <SalveLink
                        v-if="!category.weed_categoriy_title.includes('Merch')"
                        :to="getCategoryLink(category.weed_category_link)"
                        @hyperlink="gotHyperlink"
                      >
                        <span class="s-icon">
                          <NuxtImg
                            :src="$correctedImageUrl(category.weed_categoriy_image).cleanUrl"
                            alt="Shop by category"
                            format="webp"
                            :modifiers="{ q: 75 }"
                            loading="lazy"
                          />
                        </span>
                        {{ category.weed_categoriy_title }}
                      </SalveLink>
                      <a
                        v-if="category.weed_categoriy_title.includes('Merch')"
                        :href="category.weed_category_link"
                        target="_blank"
                      >
                        <span class="s-icon">
                          <NuxtImg
                            :src="$correctedImageUrl(category.weed_categoriy_image).cleanUrl"
                            alt="Shop by category"
                            format="webp"
                            :modifiers="{ q: 75 }"
                          />
                        </span>
                        {{ category.weed_categoriy_title }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="s-dropdown-submenu mx-16 text-center">
                  <a href="/shop">Partners</a>
                  <ul class="">
                    <li
                      v-for="brand of GET_HEADER_DATA.weed_brands"
                      :key="brand.weed_brand_image.id"
                      class="s-dropdown-menu-link"
                    >
                      <SalveLink
                        :to="transformURL(brand.weed_brand_object)"
                        @hyperlink="gotHyperlink"
                      >
                        <span class="s-image">
                          <NuxtImg
                            :modifiers="{ q: 75 }"
                            format="webp"
                            :src="$correctedImageUrl(brand.weed_brand_image).cleanUrl"
                            width="280"
                            loading="lazy"
                          />
                        </span>
                      </SalveLink>
                    </li>
                  </ul>
                </div>
                <div class="s-dropdown-submenu">
                  <a href="#">Strain Types</a>
                  <ul class="text-center">
                    <li
                      v-for="lineage of GET_HEADER_DATA.weed_lineage"
                      :key="lineage.weed_lineage_title"
                      class="s-dropdown-menu-link"
                    >
                      <SalveLink
                        :to="lineage.weed_lineage_object"
                        @hyperlink="gotHyperlink"
                      >
                        {{ lineage.weed_lineage_title }}
                      </SalveLink>
                    </li>
                  </ul>
                </div>
                <div class="s-dropdown-submenu ml-10">
                  <span class="s-special">
                    <NuxtLink
                      v-if="featuredProduct"
                      :to="featuredProduct.url"
                    >
                      <NuxtImg
                        :src="$correctedImageUrl(featuredProduct.image).cleanUrl"
                        alt="Shop by specials"
                        format="webp"
                        :modifiers="{ q: 75 }"
                        loading="lazy"
                        width="285"
                        height="475"
                      />
                    </NuxtLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <NuxtLink
            :to="'/loyalty-program'"
            class="s-navbar-link inline-flex h-full items-center cursor-pointer mr-3 xl:mr-7 px-6"
            >Loyalty</NuxtLink
          >
          <div class="s-dropdown-resource relative flex h-full items-center cursor-pointer mr-3 xl:mr-7 px-6">
            <a class="s-navbar-link"
              >Resources
              <IconChevron class="s-navbar-default-chevron inline-block h-2 ml-2" />
            </a>
            <div class="s-dropdown-menu-resource hidden h-auto group w-full top-20">
              <div class="flex pb-4">
                <div class="s-dropdown-resource-title w-9/12">Resources</div>
                <div class="s-dropdown-resource-title w-3/12">Find a location</div>
              </div>

              <div class="flex">
                <div class="flex w-9/12">
                  <div>
                    <NuxtLink
                      class="s-dropdown-resource-link block"
                      :to="'/about'"
                    >
                      About The Flowery
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/doctors'"
                    >
                      How-To Get a Medical Card
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/resources'"
                    >
                      What are Terpenes?
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/doctors'"
                    >
                      Find a Doctor
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="`/delivery-regions-coverage`"
                    >
                      Delivery Regions Coverage
                    </NuxtLink>
                  </div>
                  <div class="pl-24">
                    <NuxtLink
                      class="s-dropdown-resource-link block"
                      :to="'/blog'"
                    >
                      Blog
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/media'"
                    >
                      Media + Events
                    </NuxtLink>
                    <a
                      class="s-dropdown-resource-link block mt-3"
                      target="_blank"
                      rel="noopener"
                      href="https://support.theflowery.co/hc/en-us"
                    >
                      FAQ
                    </a>
                  </div>
                </div>
                <div class="w-3/12">
                  <NuxtLink
                    to="/locations"
                    class="flex justify-end"
                  >
                    <NuxtImg
                      :src="'/images/dropdown-location.png'"
                      class="-mt-2"
                      alt="Find a Flowery location"
                      width="219"
                      height="172"
                      :modifiers="{ q: 75 }"
                      loading="lazy"
                    />
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <ShopPageLink class="s-highlighted inline-flex items-center px-7 xl:px-14 mt-4 md:mt-0 h-full">
          Order now
        </ShopPageLink>
      </div>
    </div>
    <NotificationMessages />
  </div>
</template>

<script setup>
  const props = defineProps({
    topNavBar: {
      type: Function,
      required: true
    }
  })

  const yPos = ref('56px')

  onMounted(() => {
    useIntersectionObserver(
      props.topNavBar().$el,
      ([{ isIntersecting, intersectionRect }], _) => {
        if (isIntersecting) {
          yPos.value = `${intersectionRect.height}px`
        } else {
          yPos.value = '0px'
        }
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    )
  })
</script>

<script>
  import { mapGetters } from 'vuex'

  export default {
    emits: ['hyperlink'],
    computed: {
      ...mapGetters(['GET_HEADER_DATA']),

      /**
       * The featured product that appears in the navbar. If no featured product exists this will return null
       * @returns {null|{image: string, url: string}}
       */
      featuredProduct() {
        let url = this?.GET_HEADER_DATA?.weed_featured_product?.weed_featured_product_link?.post_name || null
        if (url) {
          url = '/product/' + url
        }

        const image = this?.GET_HEADER_DATA?.weed_featured_product?.weed_featured_product_image?.url || null

        // If either the image url or the hyperlink is null then we dont have a featured product
        if (url && image) {
          return {
            url,
            image
          }
        }

        return null
      }
    },

    methods: {
      getCategoryLink(link) {
        // If this is a full hyperlink just return as is
        if (link.slice(0, 4) === 'http') {
          return link
        }

        // This is a shop link so pass back as a relative path from shop
        return link
      },

      /**
       * Called when salve-link component emits a hyperlink event
       * @param link
       */
      gotHyperlink(link) {
        this.$emit('hyperlink', link)
      },
      transformURL(url) {
        // Replace 'suppliers' with 'brands' in the URL
        return url.replace('suppliers', 'brands')
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .navbar {
    position: fixed;
    width: 100vw;
    background: white;
    z-index: 101;
    transition: all 0.35s ease;
    top: v-bind(yPos);
  }

  .s-container {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.106568);
  }

  .s-navbar-link {
    @apply font-main;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    color: #002d52ff;
  }

  .s-highlighted {
    background: #f3b6d0;
    @apply font-alt;
    text-transform: capitalize;
    color: black;
  }

  .s-highlighted:hover {
    cursor: pointer;
    background: #3fb9c5;
    color: white;
  }

  .s-dropdown-menu {
    left: 95px;
    position: absolute;
    text-align: left;
    width: 950px;

    z-index: 10;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
    display: block;
    top: -1000px;
  }

  .s-dropdown {
    &:hover {
      .s-navbar-default-chevron {
        transform: scaleY(-1);
      }
      .s-dropdown-menu {
        top: 60px;
      }
    }
  }

  .s-dropdown-menu-resource {
    right: -418px;
    position: absolute;
    text-align: left;
    width: 835px;
    height: 277px;
    z-index: 10;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
    padding: 40px;
  }

  .s-dropdown-resource {
    &:hover {
      .s-navbar-default-chevron {
        transform: scaleY(-1);
      }
      .s-dropdown-menu-resource {
        display: block;
      }
    }
  }

  .s-dropdown-submenu > a {
    @apply font-main;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: #f3b6d0ff;
  }

  .s-dropdown-menu-link {
    @apply font-alt;
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #002d52ff;
    @apply my-6;
  }

  span.s-icon img {
    margin: 0.25rem;
    width: 35px;
    height: inherit;
    display: inline-block;
    text-align: left;
  }

  span.s-image img {
    width: 110px;
    height: inherit;
  }

  span.s-special img {
    height: inherit;
    width: inherit;
    max-width: 285px;
  }

  .s-dropdown-menu-top {
    background: #f3b6d0;
    height: 49px;
    position: absolute;
    width: 100%;
  }

  .s-dropdown-menu-top-left {
    height: 49px;
    @apply font-alt;
    font-size: 18px;
    color: #002d52;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid #fcbfd9;
  }

  .s-navbar-marque-text {
    @apply font-alt;
    font-size: 16px;
    font-weight: bold;
    color: #002d52;
    white-space: nowrap;
  }

  .s-navbar-marque-btn {
    @apply font-main;
    font-weight: 700;
    font-size: 9px;
    color: #3fb9c5;
    border-radius: 14px;
    text-transform: uppercase;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
  }

  .s-dropdown-resource-link {
    color: #002d52;
    @apply font-alt;
    font-size: 18px;
    font-weight: bold;
  }

  .s-dropdown-resource-title {
    @apply font-main;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 18px;
    color: #f3b6d0;
  }
</style>
